import React from "react"
import Layout from "../components/Layout"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Heading from "../components/Heading"
import PhotoMasonry from "../components/Masonry"

export default function DigitalProjectDetails({ data: { graphCmsDigital } }) {
  return (
    <Layout title="Katharina Kohler | Digital" description={graphCmsDigital.heading + " - " + graphCmsDigital.description + " by Katharina Kohler, Berlin, Germany"}>
      <div className="flex justify-end">
        <Link to="/digital">
          <Heading>Digital</Heading>
        </Link>
      </div>
      <div className="flex justify-center">
        <div>
          <Link to="/digital" className="block hover:font-bold hover:underline">
            &lt; back
          </Link>
          {graphCmsDigital.images.length === 1 ? ( // if only one image, show it full width
            <GatsbyImage
              key={graphCmsDigital.images[0].id}
              alt={graphCmsDigital.heading}
              image={getImage(graphCmsDigital.images[0].localFile)}
            />
          ) : ( // if more than one image, show them in a masonry
            <PhotoMasonry>
              {graphCmsDigital.images.map(image => (
                <GatsbyImage
                  key={graphCmsDigital.remoteId}
                  alt={graphCmsDigital.heading}
                  image={getImage(image.localFile)}
                  className="mb-4"
                />
              ))}
            </PhotoMasonry>
          )}

          <h2 className="text-md sm:text-xl font-bold mt-2">
            {graphCmsDigital.heading}
          </h2>
          <h3
            className={
              "text-xs sm:text-sm" + graphCmsDigital.subheading ? "hidden" : ""
            }
          >
            {graphCmsDigital.subheading}
          </h3>
          <p>{graphCmsDigital.description}</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DigitalProjectPage($remoteId: ID) {
    graphCmsDigital(remoteId: { eq: $remoteId }) {
      heading
      subheading
      description
      remoteId
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
